<template>
  <div class="system-under-maintenance">
    <h1>HỆ THỐNG ĐANG TẠM KHÓA</h1>
    <p>
      Hệ thống đang tạm khóa để bảo trì. Vui lòng quay lại sau.
    </p>
    <img src="/img/locked.png" alt="" />
  </div>
</template>

<script>
export default {
  name: "Locked",
};
</script>

<style>
.system-under-maintenance {
  text-align: center;
  margin: 100px auto;
}

h1 {
  font-size: 20px;
  font-weight: bold;
}

p {
  font-size: 16px;
}

a {
  display: inline-block;
  margin-top: 10px;
  background-color: #000;
  color: #fff;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 5px;
}
</style>
